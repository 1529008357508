import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image'

function Instructions() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Visa instruktioner
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Interaktivt släktträd</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='flex flex-col gap-1'>
                Du kan förminska och förstora släktträdet med musens rulle.<p/>
                Du kan flytta på släktträdet genom att trycka på musknappen och sedan flytta på musen i den riktning du vill röra dej.<p/>
                Klicka på ett foto eller en ikon för att se innehållet av personens tabell ur boken.<p/>
                Klicka 'Öppna tabellen i boken' nederst i faktarutan för att se originalet.<p/>
                Klicka på två foton eller ikoner efter varann för att se hur dessa är släkt, dvs vem de gemensamma stamfädrarna är.<p/>
            </div>
            <p className='mt-4'>
                Vi har samlat all tabelldata från <b>delarna I, II och III ur släktboken Ätterna Knorring</b> i detta släktträd.
            </p>
            <Image fluid src='tree.png'></Image>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export {
    Instructions
}