import { useState } from 'react'
import Form from 'react-bootstrap/Form'
const ESC = 27
const RET = 13

function Search({ onChange, onSearch }) {
    const [value, setValue] = useState('')

    const _onChange = (ev, val) => {
        setValue(ev.target.value)
        onChange(ev.target.value)
    }

    const _onKeyDown = (ev) => {
        if (ev.keyCode === ESC) {
            setValue('')
        } else if (ev.keyCode === RET) {
            onSearch(value)
        }
    }

    return (
        <div>
            <Form.Control size="md" type="text" placeholder="Sök namn, tabell" onChange={_onChange} value={value} onKeyDown={_onKeyDown}/>
        </div>
    )
}

export {
    Search
}