import './App.css';
import _ from 'lodash'
import { CloseButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Treechart from './Treechart'
import { Tip } from './Tip';
import { Instructions } from './Instructions';

import t1 from './data/table1.json'
import t2 from './data/table2.json'
import t3 from './data/table3.json'
import t4 from './data/table4.json'
import t5 from './data/table5.json'
import t6 from './data/table6.json'
import t7 from './data/table7.json'
import t8 from './data/table8.json'
import t9 from './data/table9.json'
import t10 from './data/table10.json'
import t11 from './data/table11.json'
import t12 from './data/table12.json'
import t13a from './data/table13-1.json'
import t13b from './data/table13-2.json'
import t14 from './data/table14.json'
import t15217 from './data/table15-17.json'
import t18a from './data/table18-1.json'
import t18b from './data/table18-2.json'
import t19to21 from './data/table19-21.json'
import t22 from './data/table22.json'
import t23 from './data/table23.json'
import t24 from './data/table24.json'
import t25 from './data/table25.json'
import t26 from './data/table26.json'
import t27 from './data/table27.json'
import t28 from './data/table28.json'
import t29 from './data/table29.json'
import t30 from './data/table30.json'
import t31 from './data/table30.json'

import { useCallback, useEffect, useState } from 'react';

import images from './imageIds'
import { Search } from './Search';
import Pdf from './Pdf';

//const treedata = [...t1, ...t2, ...t3]
const treedata = _.uniqBy([...t12, ...t13a, ...t13b,  ...t11, ...t8,...t7, ...t6,...t5, ...t4, 
  ...t3, ...t2, ...t1, ...t9, ...t10, ...t14, ...t15217, ...t18a, ...t18b,
  ...t19to21, ...t22, ...t23, ...t24, ...t25, ...t26, ...t27, ...t28, ...t29, ...t30, ...t31], 'id')
_.forEach(treedata, node => {
  if (images.includes(node.id)) {
    console.log(`${node.id}.jpg`)
    node.image = `${node.id}.jpg`
  }
  if (node.spouses.length) {
    _.forEach(node.spouses, spouse => {
      if (images.includes(spouse.id)) spouse.image = `${spouse.id}.jpg`
    })
  }
})

function getShortestPath(root, node1, node2) {
  if (!root || !node1 || !node2) return []
  if (!node1.path || !node2.path) return []

  let node1Path = _.uniq(_.map(_.compact(node1.path(root)), 'id'))
  let node2Path = _.uniq(_.map(_.compact(node2.path(root)), 'id'))

  console.log({ node1Path, node2Path })
  for (let i = 0; i < node1Path.length; i++) {
    const node = node1Path[i];
    if (node2Path.includes(node)) {
      const intersectNode = node;
      const intersectIndex = node2Path.indexOf(intersectNode);
      const fromNodes = node1Path.slice(0, i)
      const toNodes = node2Path.slice(0, intersectIndex + 1)
      const path = _.compact(fromNodes.concat(toNodes))
      console.log(path)
      return path// .map(node => node.data.name);
    }
  }
  return [];
}

// const treedata = _.uniqBy([...t13b,...t12, ...t4, ...t3, ...t1], 'id')
// console.log(treedata)
function App() {
  const [ tipId, setTipId ] = useState('')
  const [ tip, setTip ] = useState('')
  const [ rootNode, setRootNode ] = useState(undefined)
  const [ highlightNodes, setHighlightNodes] = useState([])
  const [ highlightPath, setHighlightPath ] = useState([])
  const [ searchNodes, setSearchNodes ] = useState([])
  const [ displayPage, setDisplayPage ] = useState()

  useEffect(() => {
    if (!tipId) return
    fetch('/api/GetTooltip?id='+ tipId)
    .then(data => data.text())
    .then(text => setTip(text))
  }, [tipId])

  useEffect(() => {
    if (highlightNodes.length === 2) {
      setHighlightPath(getShortestPath(rootNode, ...highlightNodes))
    } else {
      setHighlightPath(_.map(highlightNodes, 'id'))
    }
  }, [ highlightNodes, rootNode])

  const tipUpdated = useCallback(node => {
    // user clicks background
    if (!node || '' === node) {
      setHighlightNodes([])
      setTipId(undefined)
      return 
    }
    if (node.id !== tipId) {
      setTipId(node.id)
    } else {
      setTipId('')
    }
    
    if (_.isEmpty(highlightNodes)) {
      setHighlightNodes([ node ])
    } else {
      if (highlightNodes.length === 1) {
        // toggle on / off is same node is clicked
        if (node.id === highlightNodes[0].id) {
          setHighlightNodes([])
        } else {
          setHighlightNodes(highlightNodes => [...highlightNodes, node])
        }
      } else {
        setHighlightNodes([ node ])
      }
    }
  }, [ highlightNodes, setHighlightNodes, tipId ])

  function _setRoot(node) {
    if (!rootNode) setRootNode(node)
  }

  // TODO: Handler for user presses enter in search box
  const onSearch = search => undefined

  // Handler for user types a search string
  const onSearchStringChange = s => {
    const foundNodes = treedata.filter(e => {
      // match both partial name, and Tab. ID
      return (
        e.name?.toLowerCase().includes(s?.trim().toLowerCase()) > 0 ||
        // match string / ints
        // eslint-disable-next-line
        s == e.id
      )
    })
    if (foundNodes.length && foundNodes.length < treedata.length) {
      setSearchNodes(foundNodes)
    } else {
      setSearchNodes([])
    }
    console.log(foundNodes?.length)
  }

  return (
    <>
      { /* Tooltip */}
      { tipId && <Tip tip={tip} id={tipId} onClick={tipUpdated} onDisplayPdf={setDisplayPage} /> }  

      { /* navigation header */}
      <div className='flex flex-row gap-10 items-center' style={{ position: 'fixed', width: '100%', height: '5em', maxHeight: 60, background: '#5f7087'}} gap={5}>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id={`tooltip`}>Tillbaka till Hemsidan.</Tooltip>}
          >
          <div className='flex flex-row gap-2 pl-4 cursor-pointer h-10' onClick={() => window.history.back()}>
            <CloseButton className='h-10'/>
            <img className='object-contain p-0' alt='von Knorrings vapenbild' src='vapen.png' />
          </div>
        </OverlayTrigger>

        <Instructions/>
        <Search onChange={onSearchStringChange} onSearch={onSearch}/>
      </div>

      { /* tree chart */}
      <Treechart
        data={treedata}
        onClick={tipUpdated}
        onRootNodeUpdated={node => _setRoot(node)}
        hilight={highlightPath}
        searchNodes={searchNodes}
        selectedNode={tipId}
        />

      { /* pdf overlay */}
      <Pdf displayPage={displayPage} onDismiss={() => setDisplayPage(null)}/>
    </>
  );
}

export default App;
