import { useCallback, useEffect, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import CloseButton from 'react-bootstrap/CloseButton';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// import part1 from './boken/1-126.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; 

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};
const filePrefix = `${process.env.PUBLIC_URL}/pdfs/`


export default function Pdf({ displayPage, onDismiss }) {
  const [file, setFile] = useState('')
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [, setContainerWidth] = useState();
  const [currentPage, setCurrentPage] = useState()
  const [display, setDisplay] = useState(false)
  const [height, setHeight] = useState(window.innerHeight - 100)
  const scale = 1.4

  useEffect(() => {
    if (!displayPage) {
      setDisplay(false)
      return
    }
    setCurrentPage(displayPage.page)
    setFile(filePrefix + displayPage.file)
    setDisplay(true)
  }, [ displayPage ])

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
    setHeight(window.innerHeight - 100)
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  // function onFileChange(event){
  //   const { files } = event.target;

  //   if (files && files[0]) {
  //     setFile(files[0] || null);
  //   }
  // }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    // setCurrentPage(1)
  }

  const previousPage = () => {
    setCurrentPage(val => Math.max(0, --val))
  }

  const nextPage = () => {
    setCurrentPage(val => Math.min(numPages, ++val))
  }

  // const onZoomIn = () => {
  //   setScale(val => val + 0.1)
  // }

  if (!display) return null

  return (
    <>
      {
        <div className="fixed top-0 w-screen h-screen flex justify-center drop-shadow-2xl pointer-events-none backdrop-brightness-50" ref={setContainerRef}>
          <div className='bg-white pointer-events-none'>
            <div className='relative flex flex-row justify-between w-full px-8 py-2 pointer-events-auto'>
              <CloseButton className='hover:scale-105 hover:bg-slate-50 transition-all' onClick={onDismiss}></CloseButton>
              <div className='hover:scale-105 hover:bg-slate-50 flex px-0 rounded-lg drop-shadow-sm gap-2' >
                  <button className=' rounded-lg cursor-pointer hover:bg-slate-200 py-1 px-3 transition-colors' onClick={previousPage}>{'<'}</button>
                  <div className='p-3'>{currentPage}</div>  
                  <button className='rounded-lg cursor-pointer hover:bg-slate-200 py-1 px-3 transition-colors' onClick={nextPage}>{'>'}</button>
              </div>
              <div></div>
              {
                // <button className='m-0 hover:scale-105 hover:bg-slate-200 transition-all' onClick={onZoomIn}>ZOOM</button>
              }
            </div>

            <div className='relative overflow-scroll pointer-events-auto drop-shadow p-4' style={{ height: height, width: '50vw' }} >
              <Document loading='...' file={file} onLoadSuccess={onDocumentLoadSuccess} options={options} renderMode='canvas'>
                <Page
                  key={'page_'+ currentPage }
                  pageNumber={currentPage}
                  canvasBackground='white'
                  loading={'...'}
                  height={height}
                  scale={scale}
                />
              </Document>
            </div>
          </div>
        </div>
      }
    </>
  );
}

/**
 * 
 *             {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
              />
            ))}
 */