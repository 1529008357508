const imageIds = [
    '114',
    '114-sp1',
    '117',
    '118',
    '119',
    '120',
    '121',
    '122',
    '125',
    '146',
    '147',
    '149',
    '151',
    '152',
    '152-sp1',
    '153',
    '154',
    '155',
    '156',
    'F 2',
    'F 4',
    'F 5',
    'F 9',
    'I 1',
    'I 2',
    'I 3',
    'I 5',
    'I 9',
    'I 15',
    'I 17',
    'I 18',
    'I 19',
    'I 20',
    'I 36',
    'I 38',
    'I 47',
    'I 48',
    'I 49',
    'I 51',
    'I 52',
    'I 53',
    'I 59',
    'I 60',
    'I 69',
    'I 68',
    'I 70',
    'I 74',
    'O 4',
    'O 5',
    'O 8',
    'O 9',
    'O 15',
    'O 18',
    'O 58',
    'O 83',
]

export default imageIds
