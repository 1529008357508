// import { useD3 } from './useD3';
import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { get } from 'lodash'
import FamilyNode from './FamilyNode';

let zoom = d3.zoom().scaleExtent([0.07, 2]).on('zoom', handleZoom);

function handleZoom(e) {
  d3.select('svg g').attr('transform', e.transform);
}

const t = {
  "k": 1,
  "x": -2200,
  "y": 300
}

function initZoom() {
  const mid = window.innerWidth / 2
  t.x = -9160 + mid
  d3.select('svg').call(zoom);
  d3.select('svg').call(zoom.transform, d3.zoomIdentity.translate(t.x, t.y).scale(t.k))
}

function Treechart({ data, onClick, onRootNodeUpdated, hilight, searchNodes, selectedNode }) {
  // console.log('TREECHART')

  useEffect(() => {
    setTimeout(() => {
      initZoom()
    }, 200)
  }, [])

  // t.x = -2955 + window.innerWidth / 2

  const ref = useRef()
  const content = useMemo(() => {
    const height = 5000;
    const width = 24000;

    const dataStructure = d3.stratify()
                              .id(d => d.id)
                              .parentId(d => get(d, 'parents[0].id', ''))
                              (data)
    const treeStructure = d3.tree().size([width, height])
    const information = treeStructure(dataStructure)
    // console.log('dataStructure', dataStructure)
    // console.log('data.length', data.length)
    // console.log(information.descendants())

    if (onRootNodeUpdated) onRootNodeUpdated(dataStructure)

    let lastParent = undefined
    let childIndex = 0

    const adjustText = node => {
      // always when parent has changed, reset the adjustments
      // this makes sure that leftmost (1st) child is always at normal height
      if (lastParent === node.parent) {
        childIndex++
      } else {
        childIndex = 0
        lastParent = node.parent
      }
      // move every second child slighty in y pos
      return childIndex % 2 * 10
    }

    const onBackgroundClick = () => {
      onClick(undefined)
    }

    const onNodeClick = (node) => {
      onClick(node)
    }

    const nodes = information.descendants().map((node, index, length) => {
      if (index === 0) console.log(node.x, node.y)

      let y = node.y - 60

      // adjust y of node name label so that adjacent labels don't overlap
      const adjust = adjustText(node)

      let dim = searchNodes?.length && searchNodes.filter(n => n.id === node.id).length === 0
      const found = searchNodes?.length && dim === false
      const inFocus = selectedNode && node.id === selectedNode

      if (hilight?.length) {
        if (hilight.includes(node.id) === false) dim = true
      }

      dim = dim ? 0.3 : 1

      if (inFocus && node.data?.spouses?.length) {
        const lineY = node.y - 15
        return (
          <>
          <path style={{ stroke: '#FFCCCC'}} className={'hilight'} d={`
          M${node.x},
          ${lineY} C ${node.x},
          ${lineY} ${node.x + 60},
          ${lineY} ${node.x + 60},
          ${lineY}`}
          />

          <FamilyNode key={node.id}
            node={node.data}
            hilight={hilight.includes(node.id)}
            dim={dim}
            found={found}
            onClick={() => onNodeClick(node)}
            left={node.x - 110}
            top={y}
            adjustText={adjust}
          />
        <FamilyNode key={node.id}
            node={{...node.data.spouses[0] }}
            hilight={hilight.includes(node.id)}
            dim={dim}
            found={found}
            onClick={() => onNodeClick(node)}
            left={node.x - 20}
            top={y}
            adjustText={adjust + 10}
          />
        </>

        )
      }
      return (
          <FamilyNode key={node.id}
            node={node.data}
            hilight={hilight.includes(node.id)}
            dim={dim}
            found={found}
            //isRoot={node.id === rootId}
            // onClick={onClick}
            //left={node.x * (width / 2) - 15}
            //top={node.y * (height / 2) + 65}
            onClick={() => onNodeClick(node)}
            left={node.x - 110}
            top={y}
            adjustText={adjust}
          />
      )
      })

      const connectors = information.links().map(node => {
        const parentType = get(node, 'target.data.parents[0].type', 'blood')
        let className = parentType

        if (hilight.includes(node.source.id) && hilight.includes(node.target.id)) {
          className += ' hilight'
        }

        return <path className={className} d={`M${node.source.x},${node.source.y} C ${node.source.x},${(node.source.y+node.target.y)/2} ${node.target.x},${(node.source.y+node.target.y)/2} ${node.target.x},${node.target.y}`}/>
      })

      const result = (
        <svg
        onClick={onBackgroundClick}
        height='100%'
        width='100%'
        ref={ref}
        style={{
          height: '100vh',
          marginRight: "0px",
          marginLeft: "0px"
        }}
        >
          <g>
            { connectors }
            { nodes }
          </g>
        </svg>
      )
    return result
    }, [data, onRootNodeUpdated, onClick, searchNodes, selectedNode, hilight])

    //   const circles = svg.append("g").selectAll("circle").data(information.descendants())
    //   const enter = circles.enter()
    //   enter.append("circle")
    //         .attr("cx", d => d.x)
    //         .attr("cy", d => d.y)
    //         .attr("r", 15)
    //         .attr("fill", "#aaa")
    //   enter.append("text")
    //     .attr("x", d => d.x)
    //     .attr("y", d => d.y)
    //     .text(d => { 
    //         return d.data.name
    //     })



  return content
}

export default React.memo(Treechart)
