import CloseButton from 'react-bootstrap/CloseButton';
import { find } from './PageMap'
import imageIds from './imageIds';

const Tip = ({ tip, id, onClick, onDisplayPdf }) => {
    if (!tip) return null

    const onOpen = tab => {
        const result = find(tab)
        if (result) onDisplayPdf(result)
    }

    // Add image if found
    if (imageIds.includes(id)) {
        const img = `${process.env.PUBLIC_URL}/images/${id}.jpg`
        console.log(img)
        tip = `
            <div class='flex flex-col gap-3'>
                <img class='p-0 place-self-center drop-shadow-[0_5px_5px_rgba(0,0,0,0.5)]' src='${img}' width='60%' alt='profile'/>
                ${tip}
            </div>
        `
    }

    return (
        <>
            <div className='tip mobile' >
                <CloseButton onClick={() => onClick('')}/>
                <hr/>
                <div style={{ fontSize: '14px', overflowY: 'scroll', height: '70dvh' }} dangerouslySetInnerHTML={{ __html: tip }} ></div>
            </div>
            <div className='tip desktop' >
                <CloseButton onClick={() => onClick('')}/>
                <div style={{ fontSize: '14px', overflowY: 'scroll', height: '70vh' }} dangerouslySetInnerHTML={{ __html: tip }} ></div>
                <hr className='mt-2'/>
                <button className='mt-2' onClick={ () => onOpen(id)}>Öppna tabellen i boken</button>
            </div>
        </>
    )
}

export { Tip }