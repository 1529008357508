import React, { useMemo } from 'react'

export default function FamilyNode({ node, onClick, left, top, adjustText, hilight=true, dim, found }) {
  const dates = [node.birthday, node.deathday].filter(Boolean).join(' - ')

  const nodeId = useMemo(() => {
    return node.id.split(' ').join('__')
  }, [ node.id ])

  const textBaseline = 95 + adjustText

  const _onClick = ({ ev, node }) => {
    ev.stopPropagation()
    onClick(node)
  }
  let strokeColor
  if (hilight) strokeColor = 'green'
  strokeColor = node.gender === 'female' ? '#FFDDDD' : 'lightgray'
  if (found) {
    strokeColor = 'blue'
  }

  return (
    <g
      data-tip={node.tip}
      transform={`translate(${left} ${top})`}
      width="200"
      height="200"
      style={ { cursor: 'pointer'}}
      onClick={(ev) => { _onClick({ ev, node }) }}
      id={'node-'+node.id}
      opacity={dim}
    >
      <defs>
        {node.image && (
          <pattern id={nodeId} x="0%" y="0%" height="100%" width="100%" viewBox="0 0 70 70">
            <image x="0%" y="0%" width="70" height="70" xlinkHref={`${process.env.PUBLIC_URL}/images/${node.image}`}></image>
          </pattern>
        )}
        {!node.image && (
          <pattern id={nodeId} x="0%" y="0%" height="100%" width="100%" viewBox="0 0 20 20">
            <rect width="30" height="20" fill={strokeColor} />
            <path fill={strokeColor} fillRule="evenodd" d="M10 10a4 4 0 100-8 4 4 0 000 8zm-7 8a7 7 0 1114 0H3z" clipRule="evenodd" />
          </pattern>
        )}
      </defs>

      <circle className="medium" cx="110" cy="44" r="38" fill="white" stroke={strokeColor} strokeWidth="4" />
      <circle className="medium" cx="110" cy="44" r="35" fill={ `url(#${nodeId})` } stroke="white" strokeWidth="3" />

      <text x="110" y={textBaseline} textAnchor="middle" fill="#333" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: 10 }}>{node.name + ' ' + (node.id.length > 6 ? '' : node.id) || '-'}</text>
      <text x="110" y={textBaseline + 15} textAnchor="middle" fill="#888" style={{ fontFamily: 'Arial', fontSize: 10 }}>
        {dates}
      </text>
    </g>
  )
}
//       <text x="110" y="110" textAnchor="middle" fill="#666" style={{ fontFamily: 'Arial', fontSize: 10 }}>{node.from || '-'}</text>
